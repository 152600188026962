<template>
  <div>
    <div v-touch:swipe.left="swipeHandler">Mood</div>
    <date-picker
        v-model="value1"
        format="YYYY-MM-DD"
        type="date"
        placeholder="Select date"
    />
    <date-picker 
      v-model="value5" 
      type="time" 
      placeholder="Select time"
    />
    <div class="btn btn-danger" @click="handleSubmit">submit</div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data: () => {
    return {
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
    };
  },
  name: 'TestSwipeEvent',
  methods: {
    swipeHandler () {
      console.log('always mood')
    },
    handleSubmit () {
      const date = dayjs(this.value1).format('YYYY/MM/DD')
      const time = dayjs(this.value5).format('HH:mm:ss')
      console.log("date", date)
      console.log("time", time)
      console.log(`${date} ${time}`)
    }
  }
}
</script>

<style>

</style>
