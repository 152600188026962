<template>
  <div>
    <h3>
      Payment Gateway - Identify Doku
    </h3>
    <router-link :to="{name: 'Main'}">
      <div class="btn btn-primary">
        Back to E-Menu
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'IdentifyDoku',
  mounted () {
  }
}
</script>

<style>

</style>
