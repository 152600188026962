<template>

</template>

<script>
import axios from 'axios'

export default {
  name: 'GetDataOutlet',
  mounted () {
    this.getOutlet()
  },
  methods: {
    async getOutlet () {
      if (!localStorage.getItem('token')) {
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/auth/guest/login`)
        localStorage.setItem('token', data.data)
      }
      const resultOutlet = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/outlet/${this.$route.params.outletId}`)
      const dataOutlet = {
        businessId: resultOutlet.data.data.business_id,
        outletId: this.$route.params.outletId
      }
      localStorage.setItem('data_outlet', JSON.stringify(dataOutlet))
      localStorage.removeItem('data_table')
      localStorage.setItem('with_scan', 'YES')
      this.$router.push({ name: 'Menu', params: { type: 'booking' } })
    }
  }
}
</script>

<style scoped>

</style>
