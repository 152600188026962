<template>
  <div>
    <div v-if="withScan === 'YES'">
      <div class="wrapper-icon-back" @click="goBack">
        <img src="@/assets/images/back-arrow.png" alt="Back">
      </div>
    </div>
    <div class="title">Login</div>
    <hr />
    <form @submit.prevent="handleLogin">
      <div class="form-email d-flex flex-column">
        <label for="email">Email</label>
        <input id="email" type="text" name="email" v-model="email" placeholder="please input your email">
      </div>
      <div class="form-password d-flex flex-column">
        <label for="password">Password</label>
        <input id="password" type="password" name="password" v-model="password" placeholder="please input your password">
      </div>
      <button type="submit" class="button">Login</button>
    </form>
    <div class="to-signup text-muted">don't have an account? please <span @click="pushToSignup" class="signup">Signup</span></div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'Login',
  data: () => {
    return {
      email: '',
      password: '',
      withScan: localStorage.getItem('with_scan')
    }
  },
  methods: {
    goBack () {
      const dataTable = JSON.parse(localStorage.getItem('data_table'))
      this.$router.push({ name: 'Menu', params: { tableId: dataTable.tableId } })
      localStorage.removeItem('temp_signup')
    },
    async handleLogin () {
      console.log('test login')
      try {
        if (!this.email && !this.password) {
          Swal.fire('Try again!', 'form must be filled', 'warning')
        } else {
          const result = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/customer/login`, { email: this.email, password: this.password })
          if (result.data.data.payload.customer_verified_account) {
            console.log('Waduhh', result.data.data)
            localStorage.setItem('token', result.data.data.token)
            localStorage.setItem('customer_account_id', result.data.data.payload.customer_account_id)
            localStorage.setItem('with_scan', 'NO')
            if (this.withScan === 'YES') {
              if (localStorage.getItem('temp_signup') === 'YES') {
                localStorage.removeItem('temp_signup')
                const dataTable = JSON.parse(localStorage.getItem('data_table'))
                this.$router.push({ name: 'Menu', params: { tableId: dataTable.tableId } })
                Swal.fire('Login successful', '', 'success')
              } else {
                this.$router.go(-1)
                Swal.fire('Login successful', '', 'success')
              }
            } else {
              this.$router.push({ name: 'MainAllbusiness' })
              Swal.fire('Login successful', '', 'success')
            }
          } else {
            Swal.fire('You have not verified your account', '', 'error')
          }
        }
      } catch (error) {
        Swal.fire('Login failed', 'email or password wrong, please try again', 'error')
        console.log('error nya', error.response.data.status)
      }
    },
    pushToSignup () {
      if (this.withScan === 'YES') {
        localStorage.setItem('temp_signup', 'YES')
        this.$router.push({ name: 'Signup' })
      } else {
        this.$router.push({ name: 'Signup' })
      }
    }
  }
}
</script>

<style scoped>
.wrapper-icon-back{
  cursor: pointer;
  position: absolute;
  left: 17px;
  top: 10px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-bottom: 30px;
}
.wrapper-icon-back:hover{
  transform: scale(1.05);
}
.wrapper-icon-back > img{
  width: 100%;
  height: 100%;
}
button{
  outline: none;
  border: none;
}
.signup{
  text-decoration-line: underline;
  cursor: pointer;
  color: #623863;
}
.title{
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.form-email, .form-password{
  margin: 20px 0 ;
}
input{
  border: none;
  border-bottom: 1px solid #623863;
  padding: 5px 10px;
}
input:focus{
  outline: none;
  border-bottom: 4px solid #623863;
}
.button{
  width: 100%;
  padding: 4px 0;
  border-radius: 20px;
  background-color: #623863;
  color: white;
  text-align: center;
  cursor: pointer;
}
.to-signup{
  margin-top: 20px;
  font-size: 15px;
}
@media screen and (max-width: 495px) {
  .title{
    font-size: 24px;
    font-weight: 600;
  }
  .form-email, .form-password, .button, .to-signup{
    font-size: 14px;
  }
}
</style>
