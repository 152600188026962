<template>
  <div>
    <div class="d-flex justify-content-end">
      <div class="wrapper-logo">
        <img src="@/assets/images/e-menu logo.png" alt="Logo BeetPOS">
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="d-flex justify-content-between align-items-end">
            <div>
              <h4 class="name-business-outlet">{{dataBusiness.businessName}} - {{dataBusiness.outletName}}</h4>
              <div class="wrapper-icon-back" @click="goBack">
                <img src="@/assets/images/back-arrow.png" alt="Back">
              </div>
            </div>
            <div class="d-flex align-items-center" v-if="customerAccountId">
              <router-link :to="{name: 'CartNoscan'}">
                <div class="wrapper-icon-cart">
                  <div v-if="getTotalItem > 0">
                    <div class="count-notif">
                      {{getTotalItem}}
                    </div>
                  </div>
                  <img src="@/assets/images/shopping-cart.png" alt="Cart">
                </div>
              </router-link>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div class="container list-menu">
      <div class="row">
        <div class="col-md-12 d-flex -items-center">
          <div class="image-product mr-4">
            <div class="wrapper-image" v-if="product.image">
              <img :src="product.image" alt="Product Image" @error="handlePlaceholderImg">
            </div>
            <div class="wrapper-image" v-else>
              <img src="@/assets/images/websiteplanet-dummy-540X400.png" alt="Product Image">
            </div>
          </div>
          <div>
            <div class="text-name">Name: {{product.name}}</div>
            <div v-if="product.product_category_id">
              <div class="text-category">Category: {{product.Product_Category.name}}</div>
            </div>
            <div v-else>
              <div class="text-category">Category: no category</div>
            </div>
            <div class="text-price">Price: Rp. {{product.price | numFormat}}</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <div v-if="product.description == null">
            <h5 class="description">Description</h5>
          </div>
          <div v-else class="description-product">
            <h5 class="description">Description :</h5>
            <p class="content-description">{{product.description}}</p>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-2">
        <div class="col-md-12">
          <div v-if="allAddons.length > 0">
            <h5 class="available-addon">Available Add-On</h5>
            <hr />
            <div v-for="(data, index) in allAddons" :key="index">
              <h6 class="size mb-4">{{data.name}}</h6>
              <div class="wrapper-size-choose" v-for="(data2, index2) in data.Addons" :key="index2" @click="handleIndex = index">
                <div class="choose-size d-flex justify-content-between mb-3 " @click="handleSelectAllAddons(data2, index)" :class="{selected: handleSelect.some((element, index, array) => {
                  if(element == `${index},${data2.id}`) {
                    return true
                  }})}">
                  <p class="title-choose-size">
                    {{data2.name}}
                  </p>
                  <p class="price-choose-size">
                    Rp. {{data2.price | numFormat}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h5 class="available-addon">Add-On Not Available</h5>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-2">
        <div class="col-xl-5 col-md-12">
          <h4>Notes <span class="text-muted">(optional)</span></h4>
          <textarea name="notes" id="notes" v-model="notes" rows="3"></textarea>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-xl-5 col-md-12">
          <div class="d-flex justify-content-between align-items-center">
            <div class="total-item">
              <div class="icon-minus" @click="decrement">-</div>
              {{totalItem}}
              <div class="icon-plus" @click="increment">+</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-xl-5 col-md-12">
            <div class="button-add-cart" @click="addCart">
              Add Cart
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'DetailNoscan',
  data: () => {
    return {
      paymentAvailable: false,
      product: {},
      isActive: false,
      handleSelect: [],
      allSelectAddOns: [],
      selectedSize: 1,
      selectedTopping: 1,
      allAddons: [],
      totalItem: 1,
      customerAccountId: localStorage.getItem('customer_account_id'),
      notes: '',
      size: [
        {
          id: 1,
          name: 'Standart',
          price: 0
        },
        {
          id: 2,
          name: 'Large',
          price: 2000
        },
        {
          id: 3,
          name: 'Extra Large',
          price: 4000
        }
      ],
      topping: [
        {
          id: 1,
          name: 'Bobba',
          price: 3000
        },
        {
          id: 2,
          name: 'Jelly',
          price: 3000
        }
      ],
      dataBusiness: {}
    }
  },
  mounted () {
    this.handleGetProduct()
    this.handleDataBusiness()
    console.log('allSelectAddOns', this.allSelectAddOns)
  },
  methods: {
    ...mapMutations(['SET_INCREMENT_CART']),
    async handleDataBusiness () {
      const data = JSON.parse(localStorage.getItem('main_data'))
      const resultOutlet = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/outlet/${data.idOutlet}`)
      const resultBusiness = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/business/${data.idBusiness}`)
      console.log('resultBusiness', resultBusiness.data.data)
      const resDataBusiness = {
        businessName: resultBusiness.data.data.name,
        outletName: resultOutlet.data.data.name
      }
      this.dataBusiness = resDataBusiness
      this.handlePaymentMethod(resultBusiness.data.data)
    },
    async handlePaymentMethod (business) {
      try {
        console.log('businessnye', business)
        if (business.cz_entity_id || business.doku_client_id) {
          this.paymentAvailable = true
        } else {
          this.paymentAvailable = false
        }
      } catch (error) {
        this.paymentAvailable = false
      }
    },
    async handleGetProduct () {
      const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/product/find-product/${this.$route.params.id}`)
      console.log('data.data', data.data)
      const groupAddons = JSON.parse(JSON.stringify(data.data.Group_Addons))
      console.log('groupAddons', groupAddons)
      groupAddons.map(value => {
        this.allAddons.push(value)
      })
      if (data.data.image) {
        data.data.image = `${process.env.VUE_APP_API_URL}/${data.data.image}`
      } else {
        data.data.image = ''
      }
      console.log('data ===>', data.data)
      this.product = data.data
    },
    handlePlaceholderImg (e) {
      e.target.src = 'https://via.placeholder.com/250'
    },
    handleSelectAllAddons (data2, index) {
      this.$set(this.handleSelect, index, `${index},${data2.id}`)
      console.log('this.handleSelect', this.handleSelect)
      this.$set(this.allSelectAddOns, index, data2)
    },
    myFilter: function () {
      this.isActive = !this.isActive
      console.log('hello myFilter')
    },
    goBack () {
      this.$router.go(-1)
    },
    decrement () {
      console.log('decrement')
      if (this.totalItem > 0) {
        this.totalItem = this.totalItem - 1
      } else {

      }
    },
    increment () {
      console.log('increment')
      this.totalItem = this.totalItem + 1
    },
    addCart () {
      // if (this.customerAccountId) {
      if (this.paymentAvailable) {
        if (this.totalItem > 0) {
          console.log('product akan di embeded', this.product)
          console.log('addons akan di embeded', this.allSelectAddOns)
          console.log('total item', this.totalItem)
          const addons = []
          const priceAddons = []
          let totalPriceAddons = null
          this.allSelectAddOns.map(val => addons.push(val))
          this.allSelectAddOns.map(val => priceAddons.push(val.price))
          console.log('addons', addons)
          console.log('priceAddons', priceAddons)
          if (priceAddons.length > 0) {
            const result = priceAddons.reduce((acc, curr) => {
              return acc + curr
            })
            totalPriceAddons = result
          }
          const amount = totalPriceAddons + this.product.price
          console.log('amount', amount)
          console.log('totalPriceAddons', totalPriceAddons)
          const dataCart = {
            idItem: this.product.id,
            nameItem: this.product.name,
            totalPriceAddons,
            priceItem: amount,
            descriptionItem: this.product.description ? this.product.description : null,
            imageItem: this.product.image ? this.product.image : null,
            totalItem: this.totalItem,
            updateAddons: this.handleSelect,
            fullDataAddons: addons,
            fullDataProduct: this.product,
            allAddons: this.allAddons,
            totalAmount: amount * this.totalItem,
            notes: this.notes
          }
          console.log('bismillah', dataCart)
          this.$store.commit('SET_CART', dataCart)
          this.goBack()
        } else {
          console.log('owalahh')
        }
      } else {
        Swal.fire("This merchant have't payment gateway", 'please choose another merchant', 'warning')
      }
      // } else {
      //   Swal.fire({
      //     title: 'You must login first',
      //     text: '',
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'Yes!'
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       localStorage.setItem('temp_signup', 'YES')
      //       this.$router.push({ name: 'Auth' })
      //     }
      //   })
      // }
    }
  },
  computed: {
    ...mapGetters(['getTotalItem'])
  }
}
</script>

<style scoped>
.list-menu{
  height: 525px;
}

.count-notif{
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: rgb(248, 72, 72);
  color: white;
  font-size: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -6px;
}
/* Style Cart Button */
.total-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #623863;
  height: 35px;
  width: 100%;
  color: white;
  padding: 0 25px;
  padding-bottom: 3px;
  border-radius: 10px;
}
.wrap-icon-plus {
  background-color: #623863;
  width: 35px;
  height: 35px;
  padding: 3px;
  object-fit: contain;
}
.wrap-icon-plus > img {
  width: 100%;
  height: 100%;
}
.button-add-cart{
  width: 100%;
  height: 35px;
  background-color: #623863;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}
.wrapper-icon-cart:hover {
  transform: scale(1.02);
}

.wrapper-icon-cart{
  cursor: pointer;
  width: 25px;
  height: 25px;
  object-fit: contain;
  position: relative;
}

.wrapper-icon-cart > img{
  width: 100%;
  height: 100%;
}
/* End Style Cart Button */
textarea {
 width: 100%;
 font-size: 12px;
}
p{
  margin: 0
}
.wrapper-image{
  width: 200px;
  height: 160px;
  object-fit: contain;
}
.wrapper-image > img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
}
.text-name{
  font-size: 19px;
  font-weight: 600;
}
.text-category{
  margin: 10px 0;
  font-size: 13px;
}
.text-price{
  font-size: 16px;
  font-weight: 600;
}
.description-product{
  margin-top: 20px;
  margin-bottom: 10px;
}
.wrapper-icon-back{
  width: 20px;
  cursor: pointer;
  height: 20px;
  object-fit: contain;
  margin-bottom: 30px;
}
.wrapper-icon-back:hover{
  transform: scale(1.05);
}
.wrapper-icon-back > img{
  width: 100%;
  height: 100%;
}
.choose-size{
  width: 40%;
  padding: 6px 15px;
  border: 1px solid #763877;;
  color: black;
  border-radius: 8px;
  cursor: pointer;
}
.selected{
  color: white;
  background-color: #623863;
}
.icon-minus{
  cursor: pointer;
}
.icon-plus{
  cursor: pointer;
}
.wrapper-logo{
  margin-top: 20px;
  margin-right: 20px;
  width: 200px;
  object-fit: contain;
}
.wrapper-logo > img{
  width: 100%;
}
@media only screen and (max-width: 495px) {
  .wrapper-logo{
    width: 140px;
    margin-top: 10px;
    margin-right: 10px;
  }
  .wrapper-image{
    width: 110px;
    height: 80px;
  }
  .choose-size{
    width: 100%;
    font-size: 10px;
  }
  .text-name{
    font-size: 13px;
    font-weight: 600;
  }
  .text-category{
    font-size: 9px;
  }
  .text-price{
    font-size: 12px;
  }
  .description-product{
    margin-top: 11px;
  }
  .available-addon{
    font-size: 13px
  }
  .description{
    font-weight: 700;
    font-size: 15px;
  }
  .content-description{
    font-size: 11px;
  }
  .size{
    font-size: 15px;
  }
  .topping{
    font-size: 15px;
  }
  /* Style Cart Button */
  .total-item{
    height: 25px;
  }
  .wrap-icon-plus {
    width: 32px;
    height: 32px;
  }

  .button-add-cart{
    height: 30px;
    border-radius: 10px;
  }
  h4{
    font-size: 14px;
    font-weight: 600;
  }
  /* End Style Cart Button */
}
</style>
