import { render, staticRenderFns } from "./NotifyDoku.vue?vue&type=template&id=dcac6f90&"
import script from "./NotifyDoku.vue?vue&type=script&lang=js&"
export * from "./NotifyDoku.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports