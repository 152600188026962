<template>
  <div>
    <div class="top-color" />
    <div class="top-title d-flex justify-content-end">
      <div class="wrapper-logo">
        <img src="@/assets/images/e-menu logo.png" alt="Logo BeetPOS">
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="wrapper-icon-back" @click="goBack">
            <img src="@/assets/images/back-arrow.png" alt="Back">
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Customer',
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.top-title{
  margin-top: 30px;
  padding: 0;
  padding: 0 10%;
}
.top-color{
  top: 0;
  position: absolute;
  background-color: #623863;
  width: 100%;
  height: 10px;
}
.wrapper-logo{
  margin-right: 20px;
  width: 200px;
  object-fit: contain;
}
.wrapper-logo > img{
  width: 100%;
}
.wrapper-icon-back{
  cursor: pointer;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-bottom: 30px;
}
.wrapper-icon-back:hover{
  transform: scale(1.05);
}
.wrapper-icon-back > img{
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 495px) {
  .wrapper-logo{
    width: 140px;
    margin-top: 10px;
    margin-right: 10px;
  }
  .top-title{
    padding: 0 2%;
  }
}
</style>
