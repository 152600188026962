<template>
  <div>
    <div class="container list-menu">
      <div class="wrapper-icon-back" @click="goBack">
        <img src="@/assets/images/back-arrow.png" alt="Back">
      </div>
      <div class="row">
        <div class="col-md-12 d-flex -items-center">
          <div class="image-product mr-4">
            <div class="wrapper-image" v-if="product.image" @error="handlePlaceholderImg">
              <img :src="product.image" alt="Product Image">
            </div>
            <div class="wrapper-image" v-else>
              <img src="@/assets/images/websiteplanet-dummy-540X400.png" alt="Product Image">
            </div>
          </div>
          <div>
            <div class="text-name">Name: {{product.name}}</div>
            <div v-if="product.product_category_id">
              <div class="text-category">Category: {{product.Product_Category.name}}</div>
            </div>
            <div v-else>
              <div class="text-category">Category: no category</div>
            </div>
            <div class="text-price">Price: Rp. {{product.price | numFormat}}</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <div v-if="product.description == null">
            <h5 class="description">Description</h5>
          </div>
          <div v-else class="description-product">
            <h5 class="description">Description :</h5>
            <p class="content-description">{{product.description}}</p>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-2">
        <div class="col-md-12">
          <div v-if="allAddons.length > 0">
            <h5 class="available-addon">Available Add-On</h5>
            <hr />
            <div v-for="(data, index) in allAddons" :key="index">
              <h6 class="size mb-2">{{data.name}}</h6>
              <div v-if="data.type === 'single'">
                <!-- Handle Single -->
                  <div class="wrapper-size-choose" v-for="(data2, index2) in data.Addons" :key="index2" @click="handleIndex = index">
                  <div class="choose-size d-flex justify-content-between mb-3 " @click="handleSelectAllAddons(data2, index, data)" :class="{selected: handleSelect.some((element, index, array) => {
                    if(element == `${index},${data2.id}`) {
                      return true
                    }})}">
                    <p class="title-choose-size">
                      {{data2.name}}

                    </p>
                    <p class="price-choose-size">
                      Rp. {{data2.price | numFormat}}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- <h6 class="size mb-4">{{allSelectAddOns ? allSelectAddOns : "-"}}</h6> -->
                <!-- let value = allSelectAddOns;
                 let same_id = false;
                  value.map(item => {
                    //console.log('item', item.id)
                    if (item.id == data2.id)
                    {
                      same_id = true
                    }
                  });
                   -->
                <!-- Handle Multiple -->
                <div class="wrapper-size-choose" v-for="(data2, index2) in data.Addons" :key="index2" @click="handleIndex = index">
                <div class="choose-size d-flex justify-content-between mb-3" @click="handleSelectAllAddonsMulti(data2, index, data)" :class="{selected: allSelectAddOns.some((element, index, array) => {
                  if(element.id === data2.id) {
                    return true
                  } else {
                    return false
                  }
                  })}">
                  <p class="title-choose-size">
                    {{data2.name}}
                  </p>
                  <p class="price-choose-size">
                    Rp. {{data2.price | numFormat}}
                  </p>
                </div>
              </div>
              </div>

            </div>
          </div>
          <div v-else>
            <h5 class="available-addon">Add-On Not Available</h5>
          </div>
        </div>
      </div>
      <hr />

      <div class="row mt-2">
        <div class="col-xl-5 col-md-12">
          <h4>Sales Type</h4>
          <div class="wrapper-size-choose" v-for="(data, index) in salesType" :key="index">
            <div class="choose-size d-flex justify-content-between mb-3" @click="handleSelectSalesType(data, index)" :class="{selected: getSalesType == data.id}">
              <p class="title-choose-size">
                {{data.name}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-xl-5 col-md-12">
          <h4>Notes <span class="text-muted">(optional)</span></h4>
          <textarea name="notes" id="notes" v-model="notes" rows="3"></textarea>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-xl-5 col-md-12">
          <div class="d-flex justify-content-between align-items-center" :class="[statusTable === 'used' ? 'disable-button' : 'enable-button']">
            <div class="total-item">
              <div class="icon-minus" @click="decrement">-</div>
              {{totalItem}}
              <div class="icon-plus" @click="increment">+</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-xl-5 col-md-12">
            <div class="button-add-cart" :class="[statusTable === 'used' ? 'disable-button' : 'enable-button']" @click="addCart">
              Add Cart
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Detail',
  props: {
    dataBusiness: Object
  },
  data: () => {
    return {
      dataTable: localStorage.getItem('data_table') || null,
      statusTable: '',
      paymentAvailable: false,
      costumerId: localStorage.getItem('customer_account_id'),
      product: {},
      isActive: false,
      handleSelect: [],
      allSelectAddOns: [],
      selectedSize: 1,
      selectedTopping: 1,
      allAddons: [],
      totalItem: 1,
      notes: '',
      salesType: [],
      size: [
        {
          id: 1,
          name: 'Standart',
          price: 0
        },
        {
          id: 2,
          name: 'Large',
          price: 2000
        },
        {
          id: 3,
          name: 'Extra Large',
          price: 4000
        }
      ],
      topping: [
        {
          id: 1,
          name: 'Bobba',
          price: 3000
        },
        {
          id: 2,
          name: 'Jelly',
          price: 3000
        }
      ],
      allDataTable: {}
    }
  },
  mounted () {
    this.handleGetProduct()
    this.handlePaymentMethod()
    console.log('allSelectAddOns', this.allSelectAddOns)
    this.getAllSalesType()
    this.handleGetIncluedTable()
    // console.log("get cart detail", this.getCart)
  },
  methods: {
    ...mapMutations(['SET_INCREMENT_CART', 'SET_SALES_TYPE']),
    // untuk handle jika type emenu booking agar yang ada table id nya tidak dijalankan
    handleGetIncluedTable () {
      console.log("this.dataTable", this.dataTable)
      if(this.dataTable) {
        this.handleCheckTable()
        this.handleRequireTable()
      }
    },
    async handleRequireTable () {
      try {
        const dataTable = JSON.parse(localStorage.getItem('data_table'))
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/sales-type/guest?business_id=${dataTable.businessId}&require_table=1`)
        console.log('require table', data.data)
        this.$store.commit('SET_SALES_TYPE', data.data[0].id)
      } catch (error) {
        console.log(error)
      }
    },
    handleSelectSalesType (data, index) {
      try {
        console.log('handleSelectSalesType data', data)
        console.log('handleSelectSalesType index', index)
        this.$store.commit('SET_SALES_TYPE', data.id)
      } catch (error) {
        console.log(error)
      }
    },
    async getAllSalesType () {
      try {
        // Check type emenu nya order atau booking
        let businessId
        if(this.dataTable) {
          const dataTable = JSON.parse(localStorage.getItem('data_table'))
          businessId = dataTable.businessId
        } else {
          const dataOutlet = JSON.parse(localStorage.getItem('data_outlet'))
          businessId = dataOutlet.businessId
        }
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/sales-type/guest?business_id=${businessId}`)
        console.log('salesType', data.data)
        data.data.map(value => {
          this.salesType.push(value)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async handleCheckTable () {
      try {
        const dataTable = JSON.parse(localStorage.getItem('data_table'))
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/table-management/cust/${dataTable.tableId}`)
        this.statusTable = data.data.status
        this.allDataTable = data.data
        console.log('check table', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetProduct () {
      const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/product/find-product/${this.$route.params.id}`)
      console.log('data.data', data.data)
      const groupAddons = JSON.parse(JSON.stringify(data.data.Group_Addons))
      console.log('groupAddons', groupAddons)
      groupAddons.map(value => {
        this.allAddons.push(value)
      })
      if (data.data.image) {
        data.data.image = `${process.env.VUE_APP_API_URL}/${data.data.image}`
      } else {
        data.data.image = ''
      }
      console.log('data ===>', data.data)
      this.product = data.data
    },
    async handlePaymentMethod () {
      try {
        console.log('this.dataBusiness', this.dataBusiness)
        if (this.dataBusiness.czEntityId || this.dataBusiness.dokuClientId) {
          this.paymentAvailable = true
        } else {
          this.paymentAvailable = false
        }
      } catch (error) {
        console.log(error)
        this.paymentAvailable = false
      }
    },
    handlePlaceholderImg (e) {
      e.target.src = 'https://via.placeholder.com/250'
    },
    handleSelectAllAddons (data2, index, dataGroup) {
      // this.$set(this.handleSelect, index, `${index},${data2.id}`)
      // //console.log('this.handleSelect', this.handleSelect)
      // this.$set(this.allSelectAddOns, index, data2)
      // //console.log("index all add ons", index)
      // console.log("dataGroup", dataGroup)

      const data2_temp = data2
      data2_temp.group_id = dataGroup.id

      // this.$set(this.handleSelect, index, `${index},${data2_temp.id}`)
      // console.log('this.handleSelect', this.handleSelect)
      // this.$set(this.allSelectAddOns, index, data2_temp)
      // console.log("index all add ons", index)

      if (this.allSelectAddOns.length == 0) {
        console.log('first Time')
        this.$set(this.handleSelect, index, `${index},${data2_temp.id}`)
        // this.$set(this.allSelectAddOns, index, data2)
        this.allSelectAddOns.push(data2_temp)
      } else {
        let sameIndex = 0
        let same_group = false

        this.allSelectAddOns.map((value, i) => {
          // console.log("value group_id ", value.group_id);

          if (value.group_id === data2_temp.group_id) {
            same_group = true
            sameIndex = i

            console.log('value group id', value.group_id)
            console.log('data2 group id', data2_temp.group_id)
            console.log('i', i)
            // delete
          } else {

          }
        })

        if (same_group) {
          this.allSelectAddOns.splice(sameIndex, 1)
          this.$set(this.handleSelect, index, `${index},${data2_temp.id}`)
          this.allSelectAddOns.push(data2_temp)
        } else { // handle if not same group
          this.$set(this.handleSelect, index, `${index},${data2_temp.id}`)
          // this.$set(this.allSelectAddOns, index, data2)
          this.allSelectAddOns.push(data2_temp)
        }
      }
    },
    handleSelectAllAddonsMulti (data2, index, dataGroup) {
      // console.log("dataGroup", dataGroup)

      const data2_temp = data2
      data2_temp.group_id = dataGroup.id
      const tempSelectAddOns = this.handleSelect
      // console.log("tempSelectAddOns", tempSelectAddOns)
      if (this.allSelectAddOns.length == 0) {
        console.log('first Time')
        this.$set(this.handleSelect, index, `${index},${data2_temp.id}`)
        this.allSelectAddOns.push(data2_temp)
      } else {
        let same = false
        let sameIndex = 0
        this.allSelectAddOns.map((value, i) => {
          // console.log("value", value.id);
          // console.log("data2", data2_temp.id)
          // console.log("i", i)
          if (value.id === data2_temp.id) {
            same = true
            sameIndex = i
            // delete
          } else {
          }
        })
        if (!same) {
          this.$set(this.handleSelect, index, `${index},${data2_temp.id}`)
          this.allSelectAddOns.push(data2_temp)
        } else {
          // const _indexOf = this.allSelectAddOns.indexOf(sameIndex)
          this.allSelectAddOns.splice(sameIndex, 1)
        }
      }
      // console.log('this.handleSelect sebelum', this.handleSelect)
      // console.log('this.allSelectAddOns sebelum', this.allSelectAddOns)

      // console.log("index", index)

      // this.allSelectAddOns.push(`${index},${data2.id}`);

      // this.$set(this.handleSelect, index, `${index},${data2.id}`)

      // console.log('this.handleSelect sesudah', this.handleSelect)
      // this.$set(this.allSelectAddOns, index, data2)
    },
    myFilter: function () {
      this.isActive = !this.isActive
      console.log('hello myFilter')
    },
    goBack () {
      this.$router.go(-1)
    },
    decrement () {
      console.log('decrement')
      if (this.totalItem > 0) {
        this.totalItem = this.totalItem - 1
      } else {

      }
    },
    increment () {
      console.log('increment')
      this.totalItem = this.totalItem + 1
    },
    addCart () {
      // if (this.costumerId) {
      if (this.statusTable !== 'used') {
        if (true) {
        // if (this.paymentAvailable) {
          if (this.totalItem > 0) {
            // console.log('product akan di embeded', this.product)
            // console.log('addons akan di embeded', this.allSelectAddOns)
            // console.log('total item', this.totalItem)
            const addons = []
            const priceAddons = []
            let totalPriceAddons = null
            this.allSelectAddOns.map(val => addons.push(val))
            this.allSelectAddOns.map(val => priceAddons.push(val.price))
            // console.log('addons', addons)
            // console.log('priceAddons', priceAddons)
            if (priceAddons.length > 0) {
              const result = priceAddons.reduce((acc, curr) => {
                return acc + curr
              })
              totalPriceAddons = result
            }
            const amount = totalPriceAddons + this.product.price
            // console.log('amount', amount)
            // console.log('totalPriceAddons', totalPriceAddons)
            const dataCart = {
              idItem: this.product.id,
              nameItem: this.product.name,
              priceItem: amount,
              descriptionItem: this.product.description ? this.product.description : null,
              imageItem: this.product.image ? this.product.image : null,
              totalItem: this.totalItem,
              updateAddons: this.handleSelect,
              fullDataAddons: addons,
              fullDataProduct: this.product,
              allAddons: this.allAddons,
              totalAmount: amount * this.totalItem,
              notes: this.notes,
              salesTypeId: this.getSalesType
            }

            const tempGetCart = this.getCart

            const tempFinalCart = tempGetCart

            // console.log("tempGetCart", tempGetCart)

            // let allSame = false
            let has_allSame = false
            // let indexAllSame = null;

            let totalQuantity = this.totalItem
            let totalPrice = amount * this.totalItem

            // console.log("totalQuantity before ", totalQuantity)
            // console.log("totalPrice before ", totalPrice)

            tempGetCart.map((value, index) => {
              let sameProduct = false
              let sameNote = false
              let sameAddons = false
              let sameSalesType = false

              // let allSame = false
              // console.log("looping get cart",index, value);
              // console.log("looping get dataCart", dataCart);

              // console.log("value.idItem", value.idItem); allAddons
              // console.log("dataCart.idItem", dataCart.idItem); updateAddons

              // console.log("value.fullDataAddons", value.fullDataAddons);
              // console.log("dataCart.fullDataAddons", dataCart.fullDataAddons);

              // if (value.fullDataAddons === dataCart.fullDataAddons)
              // {
              //   console.log("same addons fullDataAddons");
              // }
              let loop_fullDataAddonsLength = 0
              if (value.fullDataAddons) {
                if (value.fullDataAddons.length > 0) {
                  loop_fullDataAddonsLength = value.fullDataAddons.length
                }
              }

              let dataCart_fullDataAddonsLength = 0
              if (dataCart.fullDataAddons) {
                if (dataCart.fullDataAddons.length > 0) {
                  dataCart_fullDataAddonsLength = dataCart.fullDataAddons.length
                }
              }

              if (loop_fullDataAddonsLength == dataCart_fullDataAddonsLength) {
                if (dataCart_fullDataAddonsLength == 0) {
                  sameAddons = true
                } else {
                  let correct_match = 0
                  dataCart.fullDataAddons.map((v1, i1) => {
                    // console.log("v1 =>", v1);
                    value.fullDataAddons.map((v2, i2) => {
                      // console.log("v2 =>", v2);
                      if (v1.id == v2.id) {
                        correct_match = correct_match + 1
                      }
                    })

                    if (correct_match == dataCart_fullDataAddonsLength) {
                      sameAddons = true
                    }
                  })
                }
              }

              if (value.idItem == dataCart.idItem) {
                // console.log("same id ===> ", value.idItem , " ===" , dataCart.idItem)
                sameProduct = true
                if (value.notes == dataCart.notes) {
                  // console.log("same notes")
                  sameNote = true
                }
                console.log('same product')
                if (value.salesTypeId == dataCart.salesTypeId) {
                  sameSalesType = true
                  console.log('same sales type id')
                }
              }

              if (sameProduct && sameNote && sameAddons && sameSalesType) {
                // console.log("same item loop")
                has_allSame = true
                // indexAllSame = index
                totalQuantity = totalQuantity + value.totalItem
                totalPrice = totalPrice + value.totalAmount

                const tempdataCart = dataCart

                const dataCartTemp = {
                  idItem: this.product.id,
                  nameItem: this.product.name,
                  priceItem: amount,
                  descriptionItem: this.product.description ? this.product.description : null,
                  imageItem: this.product.image ? this.product.image : null,
                  totalItem: totalQuantity,
                  updateAddons: this.handleSelect,
                  fullDataAddons: addons,
                  fullDataProduct: this.product,
                  allAddons: this.allAddons,
                  totalAmount: totalPrice,
                  notes: this.notes,
                  salesTypeId: this.getSalesType
                }

                // tempdataCart.totalAmount = totalPrice
                // tempdataCart.totalItem = totalQuantity
                // tempFinalCart[index] = tempdataCart //lama

                tempFinalCart[index] = dataCartTemp

                // console.log("Updated Cart ", tempFinalCart)
              }
            })
            console.log('data added to cart', dataCart)
            if (!has_allSame) {
              this.$store.commit('SET_CART', dataCart)
              this.goBack()
            } else {
              // console.log("has same item")
              // console.log("tempFinalCart ====> ", tempFinalCart);

              // this.$store.commit('SET_CART', null)
              // JSON.parse(tempFinalCart);
              this.$store.commit('UPDATE_CART', tempFinalCart)

              // this.$store.commit('UPDATE_CART', tempFinalCart)
              this.goBack()
            }
          } else {
            console.log('failed')
          }
        } else {
          Swal.fire("This merchant have't payment gateway", 'please choose another merchant', 'warning')
        }
      } else {
        this.$router.push({ name: 'AllMenu' })
        Swal.fire(`Table ${this.allDataTable.name} already in use`, '', 'warning')
      }
      // } else {
      //   Swal.fire({
      //     title: 'You must login first',
      //     text: '',
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'Yes!'
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       localStorage.setItem('temp_signup', 'YES')
      //       this.$router.push({ name: 'Auth' })
      //     }
      //   })
      // }
    }
  },
  computed: {
    ...mapGetters(['getCart', 'getSalesType'])
  }
}
</script>

<style scoped>
.list-menu{
  height: 525px;
}
/* Style Cart Button */
.total-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #623863;
  height: 35px;
  width: 100%;
  color: white;
  padding: 0 25px;
  padding-bottom: 3px;
  border-radius: 10px;
}
.wrap-icon-plus {
  background-color: #623863;
  width: 35px;
  height: 35px;
  padding: 3px;
  object-fit: contain;
}
.wrap-icon-plus > img {
  width: 100%;
  height: 100%;
}
.button-add-cart{
  width: 100%;
  height: 35px;
  background-color: #623863;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}
/* End Style Cart Button */
textarea {
 width: 100%;
 font-size: 12px;
}
p{
  margin: 0
}
.wrapper-image{
  width: 200px;
  height: 160px;
  object-fit: contain;
}
.wrapper-image > img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
}
.text-name{
  font-size: 19px;
  font-weight: 600;
}
.text-category{
  margin: 10px 0;
  font-size: 13px;
}
.text-price{
  font-size: 16px;
  font-weight: 600;
}
.description-product{
  margin-top: 20px;
  margin-bottom: 10px;
}
.wrapper-icon-back{
  cursor: pointer;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-bottom: 30px;
}
.wrapper-icon-back:hover{
  transform: scale(1.05);
}
.wrapper-icon-back > img{
  width: 100%;
  height: 100%;
}
.choose-size{
  width: 40%;
  padding: 6px 15px;
  border: 1px solid #763877;;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}
.selected{
  color: white;
  background-color: #623863;
}
.icon-minus{
  cursor: pointer;
}
.icon-plus{
  cursor: pointer;
}
.disable-button{
  background-color: grey;
}
.enable-button{
  background-color: #623863;
}
@media only screen and (max-width: 495px) {
  .wrapper-image{
    width: 110px;
    height: 80px;
  }
  .choose-size{
    width: 100%;
    font-size: 10px;
  }
  .text-name{
    font-size: 13px;
    font-weight: 600;
  }
  .text-category{
    font-size: 9px;
  }
  .text-price{
    font-size: 12px;
  }
  .description-product{
    margin-top: 11px;
  }
  .available-addon{
    font-size: 13px
  }
  .description{
    font-weight: 700;
    font-size: 15px;
  }
  .content-description{
    font-size: 11px;
  }
  .size{
    font-size: 15px;
  }
  .topping{
    font-size: 15px;
  }
  /* Style Cart Button */
  .total-item{
    height: 25px;
  }
  .wrap-icon-plus {
    width: 32px;
    height: 32px;
  }

  .button-add-cart{
    height: 30px;
    border-radius: 10px;
  }
  h4{
    font-size: 14px;
    font-weight: 600;
  }
  /* End Style Cart Button */
}
</style>
